<template>
  <div>
    <!-- TODO: remove demo links -->
    <b-navbar variant="warning" style="width: 100%" v-if="!hasBreadcrumb">
      <b-navbar-brand>
        {{ $t("shop.nav.title") }}<br />
        {{ $store.getters.shopById[$route.params.id].title }}
      </b-navbar-brand>
      <b-navbar-nav>
        <b-nav-item
          :to="{ name: 'ShopDashboard', params: { id: $route.params.id } }"
          >{{ $t("shop.nav.dashboard") }}</b-nav-item
        >
        <b-nav-item
          :to="{ name: 'ShopTimeline', params: { id: $route.params.id } }"
          >{{ $t("shop.nav.timeline") }}</b-nav-item
        >
        <b-nav-item
          :to="{ name: 'ShopDevices', params: { id: $route.params.id } }"
          >{{ $t("shop.nav.devices") }}</b-nav-item
        >
        <b-nav-item :to="{ name: 'Demo', params: { id: $route.params.id } }">{{
          $t("shop.nav.appointments")
        }}</b-nav-item>
        <b-nav-item :to="{ name: 'Demo', params: { id: $route.params.id } }">{{
          $t("shop.nav.activities")
        }}</b-nav-item>
        <b-nav-item :to="{ name: 'Demo', params: { id: $route.params.id } }">{{
          $t("shop.nav.payments")
        }}</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item
          :to="{ name: 'Demo', params: { id: $route.params.id } }"
          right
        >
          <v-btn
            text
            class="mr-1"
            :to="{ name: 'ShopEdit', params: { id: $route.params.id } }"
          >
            <v-icon>mdi-lead-pencil</v-icon>
          </v-btn></b-nav-item
        >
      </b-navbar-nav>
    </b-navbar>
    <v-row justify="space-around" class="width-100pc" v-else>
      <v-col>
        <v-sheet tile elevation="0" class="width-100pc">
          <v-breadcrumbs :items="visitedItems">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                {{ item.text.toUpperCase() }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "ShopNav",
  computed: {
    visitedItems() {
      return [
        {
          text: "Shops",
          disabled: false,
          href: "/shops",
        },
        {
          text: this.$store.getters.shopById[this.$route.params.id].title,
          disabled: false,
          href: "/shops/" + this.$route.params.id,
        },
        {
          text: "Configuration",
          disabled: true,
          href: "#",
        },
      ];
    },
    hasBreadcrumb() {
      return this.$route.meta?.shopBreadcrumb;
    },
  },
  methods: {},
  mounted() {},
};
</script>
