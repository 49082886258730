<template>
  <div style="width: 100%">
    <ShopNav v-if="display" />
    <div style="display: flex; justify-content: center">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import { SHOPS_LOAD } from "@/store/modules/shops/enums";
import ShopNav from "@/components/Shop/ShopNav.vue";
export default {
  name: "Shop",
  components: {
    ShopNav,
  },
  data() {
    return { display: false };
  },
  mounted() {
    if (
      this.$store.getters.shopStatus !== "success" ||
      this.$store.getters.shopRefresh <
        (process.env.VUE_APP_API_SHOP_REFRESH_INTERVAL || 600)
    ) {
      this.$store.dispatch(SHOPS_LOAD, this.$store.getters.user.shopIds);
    }
    if (this.$store.getters.shopById[this.$route.params.id]) {
      this.display = true;
    } else {
      this.makeToast(
        "danger",
        this.$t("shop.detail.missing.title"),
        this.$t("shop.detail.missing.label")
      );
    }
  },

  methods: {
    makeToast(variant, title, label) {
      this.$bvToast.toast(label, {
        title: title,
        variant: variant || "danger",
        solid: true,
      });
    },
  },
};
</script>
